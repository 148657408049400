import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Select,
  SelectProps,
  Modal,
  SpaceBetween,
  Box,
  Container,
  Header,
  ColumnLayout,
  Popover,
  Icon,
} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { RiskConsequenceContext } from "./RiskConsequenceProvider";
import { RoleContext } from "./RoleProvider";
import EmptyState from "./EmptyState";
import { ConsequenceAssessmentTRType } from "../types";
import { NullableBoolean, paginationLabels } from "../constants";
import { getMatchesCountText } from "../utils";
import Select0to5 from "./Select0to5";
import { useLocalStorage } from "src/utils/use-local-storage";

type ContentDisplayItem = {
  id: string;
  visible: boolean;
};

const ConsequenceTable = () => {
  const [selectedCountryOption, setSelectedCountryOption] = useState<
    SelectProps.Option
  >({ label: "Select a country", value: "0" });

  const {
    dispatch,
    state,
    successfullyUpdated,
    setSuccessfullyUpdated,
    updateConsequences,
    maxScoreForRisk,
  } = useContext(RiskConsequenceContext);

  const { roleFetchComplete, userIsRiskAdmin } = useContext(RoleContext);
  const countryOptions = state.countries.map((x) => ({
    label: x.name,
    value: x.id.toString(),
  }));

  const DEFAULT_PREFERENCES = {
    pageSize: 100,
    contentDisplay: [
      { id: "risk", visible: true },
      { id: "assets", visible: true },
      { id: "safety", visible: true },
      { id: "business_ops", visible: true },
      { id: "security_ops", visible: true },
      { id: "reputation", visible: true },
      { id: "regulatory", visible: true },
      { id: "legal", visible: true },
      { id: "financial", visible: true },
      { id: "data", visible: true },
      { id: "highest", visible: true },
    ],
  };

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number;
    contentDisplay: readonly ContentDisplayItem[];
  }>("ConseqTable-Prefs", DEFAULT_PREFERENCES);

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(state.riskConsequences, {
    filtering: {
      empty: <EmptyState title="Choose a country" />,
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if (!userIsRiskAdmin && roleFetchComplete) {
    return <Navigate to="/" />
  }

  if (!roleFetchComplete) {
    return <EmptyState title="Loading Consequence Workbench..." subtitle="" action="" />
  }

  return (
    <>
      <Container>
        <Header variant="h3">Choose a country</Header>
        <Select
          selectedOption={selectedCountryOption}
          placeholder="Select Country"
          onChange={({ detail }) => {
            setSelectedCountryOption(detail.selectedOption);
            dispatch({
              Type: "SET_ACTIVE_COUNTRY",
              Cargo: {
                selectedCountry: parseInt(detail.selectedOption.value!),
              },
            });
          }}
          options={countryOptions}
          filteringType="auto"
          noMatch="No matches found"
        />
      </Container>
      <Table
        {...collectionProps}
        columnDefinitions={[
          {
            id: "risk",
            header: "Risk",
            cell: (e: ConsequenceAssessmentTRType) => <Popover
              dismissButton={true}
              position="top"
              size="large"
              triggerType="custom"
              content={e.subRiskDescription}
            >
              {e.subRiskName} <Icon name="status-info" />
            </Popover>,
            isRowHeader: true,
            width: 295,
          },
          {
            id: "assets",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Loss of assets of low strategic or operational value,
                        easily replaceable and not vital to key business
                        functions or objectives.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Loss of moderately important assets, contributing to
                        certain operations but not critical to the company's
                        core functions or long-term goals.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Significant loss of valuable assets important to several
                        business operations, impacting the effectiveness of
                        these functions.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Major loss of highly valuable and critical assets,
                        essential to the company's primary operations or
                        strategic initiatives, causing considerable challenges
                        in maintaining operational efficiency.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Catastrophic loss of assets that are irreplaceable or of
                        immense strategic importance, fundamentally compromising
                        the company's ability to achieve its primary objectives
                        and maintain key operations.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Assets
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.assets}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_ASSETS_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "safety",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>Minor injuries requiring basic first aid.</Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Injuries requiring medical attention but not causing
                        long-term health effects.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Serious injuries leading to temporary disability or
                        significant health impacts.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Severe injuries causing permanent disability or critical
                        health issues.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Fatalities or catastrophic health outcomes affecting
                        multiple individuals.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Safety
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.safety}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_SAFETY_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },

          {
            id: "business_ops",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Momentary or very minor disruptions isolated to a small
                        area of operations, quickly resolved with no lasting
                        impact.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Short-term disruptions affecting a specific department
                        or area of a site, causing a temporary operational
                        slowdown, but resolved within a short period.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Moderate disruptions impacting several departments or a
                        significant portion of a site, with the impact lasting
                        for days or weeks, causing noticeable delays or
                        inefficiencies in operations.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Major disruptions affecting multiple sites or a whole
                        region, with the impact lasting for weeks or months,
                        significantly hindering operational efficiency and
                        productivity.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Sustained disruptions impacting operations at a national
                        or multinational level, with the impact lasting for an
                        extended period (months or more), severely affecting the
                        company's ability to operate and achieve its objectives.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Business Ops
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.business_ops}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_BUSINESS_OPS_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "security_ops",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Incidents that are easily handled by routine security
                        protocols, with no additional resources required and no
                        strain on existing security personnel or systems.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Small security incidents that require a slight increase
                        in vigilance or minor adjustments in resources but are
                        generally within the capacity of existing security
                        operations.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Notable incidents that challenge existing security
                        capabilities, requiring a moderate increase in resources
                        or personnel and causing some strain on current security
                        infrastructure.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Significant security events that severely test the
                        limits of existing security measures, necessitating
                        substantial additional resources or external assistance,
                        and putting considerable strain on current security
                        personnel and systems.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Major security crises that overwhelm existing security
                        capabilities, requiring a large-scale mobilization of
                        additional resources, potentially including external
                        emergency support, and significantly disrupting normal
                        security operations.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Security Ops
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.security_ops}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_SECURITY_OPS_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "reputation",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Minor reputational issues that are contained locally,
                        unnoticed by the wider public, and causing negligible
                        concern among internal stakeholders.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Issues that are recognized at a local or community
                        level, causing some concern among internal stakeholders
                        and a limited external audience, but with little impact
                        beyond the immediate locality.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Notable reputational issues that gain regional
                        attention, affecting stakeholders at a broader level and
                        leading to some negative perception regionally,
                        requiring active reputation management strategies.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Significant reputational issues that attract national
                        attention, impacting stakeholders across the country,
                        including national customers, investors, and media,
                        causing considerable concern internally, and
                        necessitating substantial reputation restoration
                        efforts.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Severe reputational crises that have an international
                        impact, leading to a profound loss of trust among global
                        stakeholders, including international customers, global
                        investors, and the international media, with lasting
                        negative effects on the company's global image and
                        brand, and requiring extensive, long-term efforts to
                        rebuild the company's reputation at an international
                        level.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Reputation
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.reputation}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_REPUTATION_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "regulatory",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Situations involving minor breaches of local
                        regulations, limited in scope and impact, affecting only
                        small, localized operations.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Incidents that breach city or state regulations,
                        affecting a broader area but still confined to specific
                        regions or states.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Significant situations breaching national regulations,
                        impacting the company's operations across an entire
                        country.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Major incidents involving breaches of regulations in
                        multiple countries, affecting multinational operations
                        and posing serious cross-border regulatory challenges.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Critical situations that involve breaches of
                        international regulations, affecting the company's
                        global operations and posing substantial risks at an
                        international level.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Regulatory
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.regulatory}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_REGULATORY_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "legal",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Minor legal issues that are internally manageable, with
                        a very low risk of litigation and negligible impact on
                        the company's legal standing.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Legal issues that require some internal legal attention,
                        presenting a low risk of litigation, but are generally
                        resolvable without significant legal proceedings.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Notable legal challenges that necessitate significant
                        internal legal resources, with a moderate risk of
                        leading to litigation, potentially affecting the
                        company's legal strategy or requiring external legal
                        advice.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Major legal issues posing a high risk of litigation,
                        requiring extensive internal legal management and
                        potentially external legal representation, significantly
                        affecting the company's legal posture and resources.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Critical legal challenges with a very high risk of
                        extensive litigation, overwhelming the company's
                        internal legal capabilities, necessitating significant
                        external legal support, and posing serious risks to the
                        company's overall legal standing.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Legal
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.legal}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_LEGAL_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "financial",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Financial losses up to a few thousand USD, with minimal
                        impact on the company's overall financial health.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Financial losses in the range of tens of thousands USD,
                        causing some concern but generally manageable within the
                        company's budget.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Financial losses from hundreds of thousands to a few
                        million USD, impacting specific projects or budgetary
                        allocations.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Significant financial losses from tens to hundreds of
                        millions USD, affecting major business operations and
                        possibly leading to restructuring or downsizing.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Catastrophic financial losses in the billions of USD,
                        threatening the company's long-term viability and
                        possibly leading to bankruptcy or major organizational
                        overhaul.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Financial
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => {
              return (
                <Select0to5
                  value={e.financial}
                  onChange={(newVal) => {
                    dispatch({
                      Type: "UPDATE_FINANCIAL_VALUE_FOR",
                      Cargo: {
                        riskId: e.subRiskId,
                        rating: parseInt(newVal),
                      },
                    });
                  }}
                  showDescriptions={false}
                />
              );
            },
          },
          {
            id: "data",
            header: (
              <Popover
                fixedWidth
                header="Control Effectiveness Definitions"
                size="large"
                position="bottom"
                content={
                  <ColumnLayout>
                    <Container>
                      <Box variant="awsui-key-label">Rating</Box>
                      <Box variant="awsui-key-label">Definition</Box>
                    </Container>
                    <Container>
                      <Box>
                        0 - <b>Not applicable</b>
                      </Box>
                      <Box>
                        Does not result in foreseeable consequences in this
                        category
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        1 - <b>Insignificant</b>
                      </Box>
                      <Box>
                        Loss of a small amount of non-critical data, easily
                        recoverable with no significant impact on operations or
                        privacy.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        2 - <b>Minor</b>
                      </Box>
                      <Box>
                        Loss of moderate amounts of non-critical data or a small
                        amount of sensitive data, causing some concern but
                        limited operational impact.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        3 - <b>Moderate</b>
                      </Box>
                      <Box>
                        Significant loss of data including sensitive
                        information, affecting some business operations and
                        causing concern over privacy and compliance.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        4 - <b>Major</b>
                      </Box>
                      <Box>
                        Major loss of critical data, including sensitive
                        customer or business information, significantly
                        affecting operations, compliance, and trust.
                      </Box>
                    </Container>
                    <Container>
                      <Box>
                        5 - <b>Severe</b>
                      </Box>
                      <Box>
                        Catastrophic loss of large volumes of critical data,
                        including highly sensitive or proprietary information,
                        severely impacting the company's operations, reputation,
                        and legal standing.
                      </Box>
                    </Container>
                  </ColumnLayout>
                }
              >
                Data
              </Popover>
            ),
            cell: (e: ConsequenceAssessmentTRType) => (
              <Select0to5
                value={e.data}
                onChange={(newVal) => {
                  dispatch({
                    Type: "UPDATE_DATA_VALUE_FOR",
                    Cargo: {
                      riskId: e.subRiskId,
                      rating: parseInt(newVal),
                    },
                  });
                }}
                showDescriptions={false}
              />
            ),
          },
          {
            id: "highest",
            header: "Highest",
            cell: (e: ConsequenceAssessmentTRType) => {
              const maxScores = maxScoreForRisk(e.subRiskId);
              if (maxScores.score === 0) {
                return "";
              }
              return (
                <>
                  <span>{maxScores.label}</span>
                  <div>{maxScores.score}</div>
                </>
              );
            },
          },
        ]}
        columnDisplay={preferences.contentDisplay}
        enableKeyboardNavigation
        items={itemsFromUseCollection}
        loadingText="Loading resources"
        wrapLines
        trackBy="subRiskId"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find a risk"
            filteringAriaLabel="Filter risks"
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: preferences.pageSize,
              contentDisplay: preferences.contentDisplay,
            }}
            onConfirm={({ detail }) => {
              const { pageSize, contentDisplay } = detail;
              if (typeof pageSize === "number" && contentDisplay) {
                setPreferences({
                  pageSize,
                  contentDisplay,
                });
              }
            }}
            pageSizePreference={{
              title: "Page Size",
              options: [
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
              ],
            }}
            contentDisplayPreference={{
              options: [
                { id: "risk", label: "risk", alwaysVisible: true },
                { id: "assets", label: "assets" },
                { id: "safety", label: "safety" },
                { id: "security_ops", label: "security_ops" },
                { id: "business_ops", label: "business_ops" },
                { id: "reputation", label: "reputation" },
                { id: "regulatory", label: "regulatory" },
                { id: "legal", label: "legal" },
                { id: "financial", label: "financial" },
                { id: "data", label: "data" },
                { id: "highest", label: "highest" },
              ],
            }}
          />
        }
        footer={
          <Box float="right">
            <SpaceBetween size={"s"}>
              <Button onClick={updateConsequences}>Save</Button>
            </SpaceBetween>
          </Box>
        }
      />
      <Modal
        visible={successfullyUpdated !== NullableBoolean.unset}
        onDismiss={() => {
          setSuccessfullyUpdated(NullableBoolean.unset);
        }}
      >
        <div>
          {successfullyUpdated === NullableBoolean.true
            ? "Successfully saved"
            : "Failed to save"}
        </div>
      </Modal>
    </>
  );
};

export default ConsequenceTable;
