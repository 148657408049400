import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button, ColumnLayout, SelectProps, Header, Select, TextFilter, Popover, Icon } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { SurveyViewOfControlType } from "src/types";
import { ControlDataContext } from "./ControlsProvider";
import { SurveyContext } from "./SurveyProvider";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";
import EffectivenessDefinitionsLayout from "./EffectivenessDefinitionsLayout";
import { IsUtilizedOptions, EffectivenessOptions, emptyLocation, IsUtilizedValues } from "src/constants";
import CancelWarning from "./CancelWarning";
import { ModalContext } from "./useModal";

import EmptyState from "./EmptyState";
import { getMatchesCountText } from "src/utils";

const ControlsSurveyModal = () => {
  const { isShowing, hideModals, selectedEntity, setSelectedLocation } = useContext(ModalContext);
  const { controlCategories } = useContext(ControlDataContext);
  const { copyFromExistingSurvey, state, dispatch, postSurveyAnswers, postPartialSurvey } = useContext(SurveyContext);
  const { entities, refreshEntitiesAndManagers } = useContext(EntityAndManagerContext);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [showChangesWarning, setShowChangesWarning] = useState(false);
  const [hasSeenUnsavedChangesWarning, setHasSeenUnsavedChangesWarning] = useState(false);
  // Placeholder const for a Filter Category Select
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [warningText, setWarningText] = useState('');
  const [selectedCopyFromOption, setSelectedCopyFromOption] = useState<SelectProps.Option>();

  const saveRowEditingResult = async (currentItem: any, column: any, value: any) => {
    switch (column.id) {
      case "isUtilized":
        dispatch({ Type: "UPDATE_ENTITY_CONTROL_IS_UTILIZED", Cargo: { isUtilized: parseInt(value), controlId: currentItem.control_id } });
        break;
      case "effective":
        dispatch({ Type: "UPDATE_ENTITY_CONTROL_EFFECTIVENESS", Cargo: { effectiveness: parseInt(value), controlId: currentItem.control_id } });
        break;
    }
  };

  useEffect(() => {
    setShowChangesWarning(false);
    setSelectedCopyFromOption(copyFromOptions[0]);
  }, [selectedEntity]);

  const onCloseModal = () => {
    if (hasPendingChanges && !hasSeenUnsavedChangesWarning) {
      setWarningText('You have made changes that aren\'t saved. Click "Save" to keep your changes or "Cancel" to discard them.');
      setShowChangesWarning(hasPendingChanges);
      setHasSeenUnsavedChangesWarning(true);
      return false;
    }
    setHasPendingChanges(false);
    hideModals();
    setHasSeenUnsavedChangesWarning(false);
    setShowChangesWarning(false);
    setSelectedLocation(emptyLocation);
  }


  const { items, filteredItemsCount, collectionProps, filterProps } = useCollection(state.surveyQuestions, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No Controls Found"
          action={<Button>Create Control</Button>}
        />
      ),
      filteringFunction: (item, filteringText) => {
        return item.category_name.toLowerCase().includes(filteringText.toLowerCase())
          || item.control_name.toLowerCase().includes(filteringText.toLowerCase());
      },
    },
  })

  const copyFromOptions = entities
    .filter((e) => (e.survey_completed === 1))
    .sort((t1, t2) => t1.site.localeCompare(t2.site))
    .map((e) => {
      return { label: e.site, value: e.entityId };
    });

  copyFromOptions.unshift({ label: "Copy from", value: "" });


  return (<Modal
    onDismiss={() => {
      onCloseModal()
    }}
    visible={isShowing("controlsSurvey")}
    size="max"
    header={showChangesWarning ? <CancelWarning
      showWarning={showChangesWarning}
      onDismiss={() => {
        setWarningText('');
        setShowChangesWarning(false);
      }}
      warningText={warningText}
    /> : selectedEntity.site}

    footer={
      <ColumnLayout columns={2}>
        <Box float="left">
          {state.lastUpdated != "" ? `Last Updated: ${state.lastUpdated}` : ""}
        </Box>
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {
              onCloseModal();
            }}>
              Cancel
            </Button>
            {state.surveyQuestions.some((s) => s.is_utilized == -1 || s.effective === -1) && <Button
              variant="primary"
              onClick={(_event) => {
                if (state.surveyQuestions.some((s) => s.is_utilized !== -1 && s.effective === -1)) {
                  setWarningText('Please pick values for both "utilized" and "effective"');
                  setShowChangesWarning(true);
                  return false;
                }
                postPartialSurvey();
                hideModals();
                setShowChangesWarning(false);
                refreshEntitiesAndManagers();
              }}
            >
              Save
            </Button>
            }
            <Button
              variant="primary"
              onClick={(_event) => {
                if (state.surveyQuestions.some((s) => (s.is_utilized === -1 || s.effective === -1))) {
                  setWarningText('All fields must be completed before submitting. Click "Save" to save your work');
                  setShowChangesWarning(true);
                  return false;
                }
                postSurveyAnswers();
                hideModals();
                refreshEntitiesAndManagers()
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      </ColumnLayout>
    }>
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: "category_type_id",
          header: "Category",
          cell: (e: SurveyViewOfControlType) => <span>{controlCategories.find((cc) => cc.id === e.category_type_id)?.name}</span>,
          isRowHeader: true,
        },
        {
          id: "control",
          header: "Control name",
          cell: (e: SurveyViewOfControlType) => <Popover
            dismissButton={true}
            position="top"
            size="large"
            triggerType="custom"
            content={e.generalDescription}
          >
            {e.control_name} <Icon name="status-info" />
          </Popover>,
          isRowHeader: true,
        },
        {
          id: "owner",
          header: "Owner",
          cell: (e: SurveyViewOfControlType) => e.owner,
        },
        {
          id: "isUtilized",
          header: "Is utilized?",
          editConfig: {
            ariaLabel: "Utilised",
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Error loading",
            editingCell: (item, { currentValue, setValue }) => {
              return (
                <Select
                  autoFocus={true}
                  options={IsUtilizedOptions}
                  selectedOption={IsUtilizedOptions.find((efOpt) => efOpt.value == currentValue)!}
                  placeholder={IsUtilizedOptions.find((efOpt) => efOpt.value == item.is_utilized.toString())?.label}
                  onChange={(e) => {
                    //Don't allow setting the value to blank
                    if (e.detail.selectedOption.value !== "-1") {
                      setValue(e.detail.selectedOption.value);
                      setHasPendingChanges(true);
                    }
                  }}
                />
              );
            }
          },
          cell: (e) => {
            const displayValue = IsUtilizedOptions.find((eff) => eff.value === e.is_utilized.toString())?.label;
            return <>{displayValue}</>;
          },
          width: 180,
        },
        {
          id: "effective",
          header: <Popover
            fixedWidth
            header="Control Effectiveness Definitions"
            size="large"
            position="bottom"
            content={
              <EffectivenessDefinitionsLayout />
            }
          >Effective</Popover>,
          editConfig: {
            ariaLabel: "Effective",
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Error loading",
            editingCell: (item, { currentValue, setValue }) => {
              const selOption = currentValue ? EffectivenessOptions.find((efOpt) => efOpt.value == currentValue)! : EffectivenessOptions[0];
              // If is_utilized is "partial" or "yes", hide the "0" option in the effectiveness DDL
              const optionsToShow = (item.is_utilized == IsUtilizedValues.PARTIAL || item.is_utilized == IsUtilizedValues.YES) ? EffectivenessOptions.filter((eo) => eo.value !== "0") : EffectivenessOptions;
              return (
                <Select
                  autoFocus={true}
                  disabled={item.is_utilized === IsUtilizedValues.NOT_SET
                    || item.is_utilized === IsUtilizedValues.N_A
                    || item.is_utilized === IsUtilizedValues.NO}
                  options={optionsToShow}
                  selectedOption={selOption}
                  placeholder={EffectivenessOptions.find((efOpt) => efOpt.value == item.effective.toString())?.label}
                  onChange={(e) => {
                    //Don't allow setting the value to "choose utilization"
                    if (e.detail.selectedOption.value !== "-1") {
                      setValue(e.detail.selectedOption.value);
                      setHasPendingChanges(true);
                    }
                  }}
                />
              );
            }
          },
          cell: (e) => {
            const value = e.effective;
            const displayValue = EffectivenessOptions.find((eff) => eff.value === value.toString())?.label;
            return <>{displayValue}</>;
          },
          width: 250,
        }
      ]}
      columnDisplay={[
        { id: "category_type_id", visible: true },
        { id: "control", visible: true },
        { id: "owner", visible: true },
        { id: "isUtilized", visible: true },
        { id: "effective", visible: true }
      ]}
      enableKeyboardNavigation
      items={items}
      loadingText="Loading resources"
      trackBy="control_name"
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringPlaceholder="Find a control"
          filteringAriaLabel="Filter controls"
        />
      }
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Select
                selectedOption={selectedCopyFromOption!}
                onChange={({ detail }) => {
                  setSelectedCopyFromOption((copyFromOptions.find((co) => co.value === detail.selectedOption.value)));
                }}
                options={copyFromOptions}
                filteringType="auto"
                placeholder="Copy from another survey"
              />
              <Button onClick={(_evt) => {
                copyFromExistingSurvey(selectedCopyFromOption?.value || "");
              }}>Import</Button>
            </SpaceBetween>}
        ></Header>
      }
      submitEdit={saveRowEditingResult}
    />
  </Modal>
  );
};

export default ControlsSurveyModal;