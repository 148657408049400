import React, { useState } from 'react';
import {
    Box,
    Container,
    ColumnLayout,
    Textarea,
    Button,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import { NOTES_MINIMUM_LENGTH } from "../constants";

export type LessonsLearnedProps = {
    lessonsLearned: string;
    notes: string;
    onClose: (notes: string, lessonsLearned: string) => void;
    showButtons?: boolean;
}

const LessonsLearned = ({ onClose, lessonsLearned = "", notes = "", showButtons = true }: LessonsLearnedProps) => {
    const [textareaNotesValue, setTextareaNotesValue] = useState(notes ?? "");
    const [textareaLessonsLearnedValue, setTextareaLessonsLearnedValue] = useState(lessonsLearned ?? "");
    const FormInvalid = textareaNotesValue.length < NOTES_MINIMUM_LENGTH && textareaLessonsLearnedValue.length < NOTES_MINIMUM_LENGTH;
    return (
        <Container
            footer={showButtons ?
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={() => {
                                onClose(textareaNotesValue, textareaLessonsLearnedValue)

                            }}>Cancel</Button>
                        <Button
                            disabled={FormInvalid}
                            disabledReason='Either Notes or Lessons Learned is required'
                            onClick={() => {
                                onClose(textareaNotesValue, textareaLessonsLearnedValue)
                            }}>Close</Button>
                    </SpaceBetween>
                </Box> : undefined
            }
            header="Notes and Lessons Learned"
        >
            <ColumnLayout columns={2}>
                <Box variant="awsui-key-label">
                    <Textarea
                        disabled={!showButtons}
                        onChange={({ detail }) => setTextareaNotesValue(detail.value)}
                        value={textareaNotesValue}
                        placeholder={showButtons ? "Add notes here" : ""}></Textarea>
                </Box>
                <Box variant="awsui-key-label">
                    <Textarea
                        disabled={!showButtons}
                        onChange={({ detail }) => setTextareaLessonsLearnedValue(detail.value)}
                        value={textareaLessonsLearnedValue}
                        placeholder={showButtons ? "Add lessons learned here" : ""}></Textarea>
                </Box>
            </ColumnLayout>

        </Container>
    )
}

export default LessonsLearned
