import React, { useEffect, useState, createContext } from 'react';
import { Auth } from 'aws-amplify';
import { getBaseUrl } from "../utils";


export type RoleContextType = {
    loggedInUser: string;
    fakeLoggedInUser: string;
    authenticated: boolean;
    userIsRiskAdmin: boolean; //Super user
    userIsRiskManager: boolean; //ASMs and RSMs
    setLoggedInUser: (alias: string) => void;
    token: string;
    mostPowerfulRole: string;
    roleFetchComplete: boolean;
}

const RoleContext = createContext<RoleContextType>({
    authenticated: false,
    loggedInUser: "",
    fakeLoggedInUser: "",
    userIsRiskAdmin: false, //Super user
    userIsRiskManager: false, // RSMs and ASMs
    setLoggedInUser: (_alias: string) => undefined,
    token: "",
    mostPowerfulRole: "",
    roleFetchComplete: false
})


const RoleContextProvider = (props: { children: JSX.Element }) => {
    const [loggedInUser, setLoggedInUser] = useState("");
    const [fakeLoggedInUser, setFakeLoggedInUser] = useState<string>('');
    const [alias, setAlias] = React.useState(" _ ")
    const [roleFetchComplete, setRoleFetchComplete] = React.useState(false)
    const [authenticated, setAuthenticated] = React.useState(false);
    const [token, setToken] = useState("");
    const [userIsRiskManager, setUserIsRiskManager] = useState(false);
    const [userIsRiskAdmin, setUserIsRiskAdmin] = useState(false);


    useEffect(() => {
        Auth.currentAuthenticatedUser().then((cognitoUserData) => {
            const rawAlias = cognitoUserData.getUsername();
            setAuthenticated(true);
            setAlias(rawAlias);
            setToken(cognitoUserData.getSignInUserSession().getIdToken().getJwtToken());
            const actualAlias = rawAlias.split('_')[1];
            setLoggedInUser(actualAlias);
         
        }).catch((err) => {
            Auth.federatedSignIn({
                customProvider: 'AmazonFederate'
            }).catch((err) => {
                console.log('Error trying to sign user in via Federate..', err);
            })
        });
    }, []);

    const roleFetcher = async () => {
        const roleCheckURL = `${getBaseUrl()}/roleCheck`;
        const secureRoleRes = await fetch(roleCheckURL, {
            method: 'POST',
            headers: new Headers({
                "wowie": token,
                "Content-Type": 'text/plain',
                "Access-Control-Allow-Origin": "*"
            }),
            body: JSON.stringify({ alias },
            )
        })
        const secureRoleJson = await secureRoleRes.json();
        setMostPowerfulRole(secureRoleJson.role);
        switch (secureRoleJson.role) {
            case "admin":
                setUserIsRiskAdmin(true);
                setUserIsRiskManager(true);
                break;

            case "manager":
                setUserIsRiskAdmin(false);
                setUserIsRiskManager(true);
                break;

            default:
                setUserIsRiskAdmin(false);
                setUserIsRiskManager(false);
                break;
        }
        setRoleFetchComplete(true)
    }

    useEffect(() => {
        if (alias.length > 0 && token.length > 0) {
            roleFetcher();
        }
    }, [alias, token]);



    const [mostPowerfulRole, setMostPowerfulRole] = useState("unauthorized");
    //let admins simulate being a manager using the query string
    useEffect(() => {
        if (userIsRiskAdmin) {
            const qs = window.location.search;
            let qsMgr: string | null = null;

            const keyValPairs = qs?.slice(1).split("&");
            for (let i = 0; i < keyValPairs.length; i++) {
                const thisKey = keyValPairs[i].split("=")[0];
                if (thisKey === "mgr") {
                    qsMgr = keyValPairs[i].split("=")[1];
                    break;
                }
            }

            setFakeLoggedInUser(qsMgr === null ? "" : qsMgr)
        }
    }, [window.location, roleFetchComplete]);

    return <RoleContext.Provider value={{
        authenticated,
        fakeLoggedInUser,
        loggedInUser,
        userIsRiskAdmin,
        userIsRiskManager,
        setLoggedInUser,
        token,
        mostPowerfulRole,
        roleFetchComplete
    }}>{props.children}</RoleContext.Provider>;
}

export { RoleContext, RoleContextProvider }