import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { ControlType } from "src/types";
import { ControlDataContext } from "./ControlsProvider";
import { paginationLabels } from "../constants";
import { ModalContext } from "./useModal";
import { Icon } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "./EmptyState";
import { RoleContext } from "./RoleProvider";

const ControlsTableForWorkbench = () => {
  const [selectedItems, setSelectedItems] = React.useState<ControlType[]>([]);
  const { showModal } = useContext(ModalContext);
  const { allControls, setSelectedControlID, controlCategories } = useContext(
    ControlDataContext
  );
  const {roleFetchComplete, userIsRiskAdmin} = useContext(RoleContext);

  const [preferences, setPreferences] = useState({
    pageSize: 50,
    contentDisplay: [
      { id: "category_type_id", visible: true },
      { id: "control_name", visible: true },
      { id: "owner", visible: true },
      { id: "depth", visible: true },
      { id: "status", visible: true },
    ],
  });

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allControls, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if(!userIsRiskAdmin && roleFetchComplete){
    return <Navigate to="/" />;
  }

  if(!roleFetchComplete) {
    return <EmptyState title="Loading controls..." subtitle="" action="" />
  }

  return (
    <Table
      {...collectionProps}
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
        setSelectedControlID(detail.selectedItems[0].id);
      }}
      selectedItems={selectedItems}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${
            selectedItems.length === 1 ? "item" : "items"
          } selected`,
        itemSelectionLabel: ({ selectedItems }, item) =>
          (item as { control_name: string }).control_name,
      }}
      columnDefinitions={[
        {
          id: "category_type_id",
          header: <span data-testid="category-type-header">Category Type</span>,
          cell: (e: ControlType) => (
            <span>
              {
                controlCategories.filter(
                  (cc) => cc.id === e.category_type_id
                )[0]!.name
              }
            </span>
          ),
          sortingField: "category_type_id",
          isRowHeader: true,
          width: 200,
        },
        {
          id: "control_name",
          header: "Control name",
          cell: (e: ControlType) => <span data-testid="control_name">{e.control_name}</span>,
          sortingField: "control_name",
          isRowHeader: true
        },
        {
          id: "owner",
          header: "Owner",
          cell: (e: ControlType) => <span data-testid="owner">{e.owner_name}</span>,
          sortingField: "owner_name"
        },
        {
          id: "status",
          header: "Status",
          cell: (e: ControlType) => {
            return e.status === "active" ? (
              <Box><Icon data-testid="status-active" name="security" size="small" variant="success" /> Active</Box>
           ) : (
              <Box><Icon data-testid="status-inactive" name="security" size="small" variant="error" /> Inactive</Box>
            );
          },
          sortingField: "status"
        },
      ]}
      columnDisplay={preferences.contentDisplay}
      enableKeyboardNavigation
      items={itemsFromUseCollection}
      loadingText="Loading resources"
      selectionType="single"
      filter={
        <TextFilter
          {...filterProps}
          countText={filteredItemsCount + " matches"}
          filteringPlaceholder="Find a control"
          filteringAriaLabel="Filter controls"
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <ButtonDropdown
                onItemClick={(evt) => {
                  if (evt.detail.id === "addControl") {
                    showModal("createControl");
                  } else if (evt.detail.id === "controlWorkbench") {
                    showModal("controlWorkbench");
                  }
                  setSelectedItems([]);
                }}
                items={[
                  {
                    text: "Add Control",
                    id: "addControl",
                    disabled: false,
                  },
                  {
                    text: "Edit Control",
                    id: "controlWorkbench",
                    disabled: selectedItems.length === 0,
                  },
                ]}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        ></Header>
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    />
  );
};

export default ControlsTableForWorkbench;
