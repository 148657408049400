import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  Container,
  Header,
  Select,
  Input,
  SelectProps,
  RadioGroup,
  FormField,
  Form,
  Textarea,
  Grid,
  ColumnLayout
} from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";

import { ProtectiveGroups, emptyLocation, RegionOptions } from "../constants";
import { EntityType, SecurityManagerType } from "src/types";
import Select0to5 from "./Select0to5";

interface EditEntityModalProps {
  entity: EntityType
}
const CS_EditEntityModal = (props: EditEntityModalProps) => {
  const { entity } = props;
  const { isShowing, hideModals, setSelectedLocation, selectedEntity } = React.useContext(
    ModalContext
  );
  const {
    address: addressStartingValue,
    carver_assessment: carverAssessmentNeededStartingValue,
    aor: aorStartingValue,
    ASM: asmStartingValue,
    city: cityStartingValue,
    confidentialOrRestricted: confidentialOrRestrictedStartingValue,
    consequenceSet: consequenceSetStartingValue,
    country: countryStartingValue,
    contextStatement: contextStatementStartingValue,
    isActive: isActiveStartingValue,
    locationType: locationTypeStartingValue,
    site: siteStartingValue, //site
    type: typeStartingValue,
    region: regionStartingValue,
    RSM: rsmStartingValue,
    PSEC: psecStartingValue,
    protectiveGroup: protectiveGroupStartingValue,
    protectiveRequirements: protectiveRequirementsStartingValue,
    state_province: state_provinceStartingValue,
    carver_criticality: carver_criticalityStartingValue,
    carver_accessibility: carver_accessibilityStartingValue,
    carver_recoverability: carver_recoverabilityStartingValue,
    carver_vulnerability: carver_vulnerabilityStartingValue,
    carver_effect: carver_effectStartingValue,
    carver_recognizability: carver_recognizabilityStartingValue,
  } = entity;



  const { postOrPutChanges, aors, selectedRegion, setSelectedRegion, countries, selectedAOR, setSelectedAOR, managers, refreshEntitiesAndManagers } = React.useContext(EntityAndManagerContext);

  const isAddingNewEntity = entity.site === "";

  const [selectedTypeOption, setSelectedTypeOption] = useState<SelectProps.Option>({});
  const [selectedRegionOption, setRegionOption] = useState<SelectProps.Option>({});
  const [selectedProtectiveGroupOption, setSelectedProtectiveGroupOption] = useState<SelectProps.Option>({});
  const [selectedRSMOption, setSelectedRSMOption] = useState<SelectProps.Option>({});
  const [selectedASMOption, setSelectedASMOption] = useState<SelectProps.Option>({});
  const [selectedAorOption, setSelectedAorOption] = useState<SelectProps.Option>({});
  const [selectedCountryOption, setSelectedCountryOption] = useState<SelectProps.Option>({});
  const [selectedLocationTypeOption, setSelectedLocationTypeOption] = useState<SelectProps.Option>({});

  const [selectedCarverCriticality, setSelectedCarverCriticality] = useState(0);
  const [selectedCarverAccessibility, setSelectedCarverAccessibility] = useState(0);
  const [selectedCarverRecoverability, setSelectedCarverRecoverability] = useState(0);
  const [selectedCarverVulnerability, setSelectedCarverVulnerability] = useState(0);
  const [selectedCarverEffect, setSelectedCarverEffect] = useState(0);
  const [selectedCarverRecognizability, setSelectedCarverRecognizability] = useState(0);
  const [totalCarverScore, setTotalCarverScore] = useState("-")

  const [isDirty, setIsDirty] = useState(false);
  const protectiveGroupOptions = ProtectiveGroups.map((pg) => ({ label: pg, value: pg }));
  let ASMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
  let RSMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
  let AOROptions = aors.map((x) => ({ label: x, value: x }));
  AOROptions.unshift({ label: "Select AOR", value: "" });
  let countryOptions = countries.map((x) => ({ label: x, value: x }));
  countryOptions.unshift({ label: "Select country", value: "" });

  const typeOptions = [
    { label: "Location", value: "location" },
    { label: "Event / Activity", value: "event-activity", disabled: true },
    { label: "Asset / Object", value: "asset-object", disabled: true },
    { label: "Organization", value: "org", disabled: true },
    { label: "Person", value: "person", disabled: true },
  ];


  const locationTypeOptions = [
    { label: "Corporate Location", value: "Corporate" },
    { label: "Subsite Location", value: "Subsite" },
    { label: "Offsite Location", value: "Offsite" },
  ];


  const localCopyOfEntity = { ...entity };

  const [isActive, setIsActive] = useState(isActiveStartingValue);
  const [isConfidentialOrRestricted, setIsConfidentialOrRestricted] = useState(confidentialOrRestrictedStartingValue);
  const [carverAssessmentNeeded, setCarverAssessmentNeeded] = useState(carverAssessmentNeededStartingValue);

  const [site, setSite] = useState(siteStartingValue);
  const [PSEC, setPSEC] = useState(psecStartingValue);
  const [state_province, setState_province] = useState(state_provinceStartingValue);
  const [city, setCity] = useState(cityStartingValue);
  const [address, setAddress] = useState(addressStartingValue);
  const [contextStatement, setContextStatement] = useState(contextStatementStartingValue);
  const [protectiveRequirements, setProtectiveRequirements] = useState(contextStatementStartingValue);


  const [consequenceSet, setConsequenceSet] = useState(consequenceSetStartingValue);
  const heading = isAddingNewEntity ? "Add New Entity" : `Edit ${site}`;


  // This useEffect's job is to keep the fields in sync while the entity is being loaded
  // There's probably a better way to handle this. If I take out this apparently redundant useEffect, the form is empty eventhough we've got an
  // entity to edit.  See https://stackoverflow.com/questions/58818727/react-usestate-not-setting-initial-value
  useEffect(() => {
    if (isDirty) {
      return;
    }

    setIsActive(isActiveStartingValue);

    if (regionStartingValue !== "") {
      setSelectedRegion(regionStartingValue);
      setRegionOption(RegionOptions.filter((e) => e.value === regionStartingValue)[0]);
    } else {
      setRegionOption(RegionOptions[0]);
      setSelectedAorOption(AOROptions[0]);
      setSelectedCountryOption(countryOptions[0]);
    }
    if (aorStartingValue && aorStartingValue !== "") {
      setSelectedAOR(aorStartingValue);
      setSelectedAorOption(AOROptions.filter((e) => e.value == aorStartingValue)[0]);
    }

    ASMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
    RSMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));

    countryOptions = countries.map((x) => ({ label: x, value: x }));
    if (countryStartingValue !== "") {
      setSelectedCountryOption(countryOptions.filter((e) => e.value == countryStartingValue)[0])
    }

    setSelectedTypeOption(typeOptions.filter((e) => e.value == typeStartingValue)[0]);
    setSelectedLocationTypeOption(locationTypeOptions.filter((e) => e.value == locationTypeStartingValue)[0]);
    setSite(siteStartingValue);
    setSelectedRSMOption(RSMOptions.filter((e: SelectProps.Option) => e.value == rsmStartingValue)[0]);
    setSelectedASMOption(ASMOptions.filter((e: SelectProps.Option) => e.value == asmStartingValue)[0]);
    setPSEC(psecStartingValue);
    setSelectedProtectiveGroupOption(protectiveGroupOptions.filter((e) => e.value == protectiveGroupStartingValue)[0])
    setConsequenceSet(consequenceSetStartingValue);
    setState_province(state_provinceStartingValue);
    setCity(cityStartingValue);
    setAddress(addressStartingValue);
    setContextStatement(contextStatementStartingValue);
    setProtectiveRequirements(protectiveRequirementsStartingValue);
    setCarverAssessmentNeeded(carverAssessmentNeededStartingValue);
    setSelectedCarverAccessibility(carver_accessibilityStartingValue);
    setSelectedCarverCriticality(carver_criticalityStartingValue);
    setSelectedCarverRecognizability(carver_recognizabilityStartingValue);
    setSelectedCarverRecoverability(carver_recoverabilityStartingValue);
    setSelectedCarverEffect(carver_effectStartingValue);
    setSelectedCarverVulnerability(carver_vulnerabilityStartingValue);

  }, [entity.site, selectedRegion, managers[0], selectedEntity.entityId]);

  useEffect(() => {
    if (isDirty) {
      setSelectedAorOption(AOROptions[0]);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (isDirty) {
      setSelectedCountryOption(countryOptions[0]);
    }
  }, [selectedAOR]);

  useEffect(() => {
    const total = selectedCarverCriticality + selectedCarverAccessibility + selectedCarverRecoverability
      + selectedCarverVulnerability + selectedCarverEffect + selectedCarverRecognizability;
    setTotalCarverScore(total !== 0 ? total.toString() : "-");
  }, [selectedCarverCriticality,
    selectedCarverAccessibility,
    selectedCarverRecoverability,
    selectedCarverVulnerability,
    selectedCarverEffect,
    selectedCarverRecognizability]);

  const allFieldsAreValid = () => {
    return site.length > 0
      && selectedTypeOption !== undefined
      && selectedProtectiveGroupOption !== undefined
      && selectedRegionOption.value !== ""
      && selectedAorOption.value !== ""
      && selectedCountryOption.value !== ""
      && state_province.length > 0
      && city.length > 0
      && address.length > 0
      && selectedRSMOption !== undefined
      && selectedASMOption !== undefined
      && PSEC.length > 0
      && consequenceSet.length > 0
      && selectedLocationTypeOption !== undefined
      && contextStatement.length > 0
      && protectiveRequirements.length > 0;
  }

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("editEntity")}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {
              setIsDirty(false);
              setSelectedLocation(emptyLocation);
              setSelectedRegion(emptyLocation.region);
              setSelectedAOR(emptyLocation.aor);
              setSelectedCountryOption(countryOptions[0]);
              hideModals();
            }}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(_event) => {
                if (allFieldsAreValid()) {
                  postOrPutChanges({
                    entityId: entity.entityId,
                    aor: selectedAorOption.value!,
                    consequenceSet,
                    isActive,
                    confidentialOrRestricted: isConfidentialOrRestricted,
                    site,
                    type: selectedTypeOption.value!,
                    region: selectedRegionOption.value!,
                    country: selectedCountryOption.value!,
                    RSM: selectedRSMOption.value!,
                    ASM: selectedASMOption.value!,
                    PSEC,
                    protectiveGroup: selectedProtectiveGroupOption.value!,
                    dateCreated: localCopyOfEntity.dateCreated,
                    lastModified: localCopyOfEntity.lastModified,
                    state_province,
                    city,
                    address,
                    locationType: selectedLocationTypeOption.value!,
                    contextStatement,
                    protectiveRequirements,
                    site_risk_avg: 0,//these next three are ignored in the API handler
                    i90: 0, //num of incidents in the last 90 days
                    avg_effect: 0,
                    carver_assessment: carverAssessmentNeeded,
                    carver_accessibility: selectedCarverAccessibility,
                    carver_criticality: selectedCarverCriticality,
                    carver_effect: selectedCarverEffect,
                    carver_recognizability: selectedCarverRecognizability,
                    carver_vulnerability: selectedCarverVulnerability,
                    carver_recoverability: selectedCarverRecoverability,
                    survey_completed: 0,
                    survey_completed_date: "",
                    survey_partially_completed: 0,
                    survey_partially_completed_date: ""
                  });
                  setSelectedLocation(emptyLocation);
                  setIsDirty(false);
                  refreshEntitiesAndManagers();
                }

              }}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Container
        header={
          <Header
            variant="h2"
          >
            {heading}
          </Header>
        }
      >
        <div className="body">
          <div className="modal-body">
            <Header variant="h1">Site: {site}</Header>
            <div className="admin-section">
              <div id="entity-status">
                Status:
                <fieldset>
                  <RadioGroup
                    onChange={({ detail }) => {
                      setIsDirty(true);
                      setIsActive(detail.value === "1" ? 1 : 0)
                    }}
                    value={"" + isActive}
                    items={[
                      { value: "1", label: "Active" },
                      { value: "0", label: "Inactive" },
                    ]}
                  />
                </fieldset>
              </div>
            </div>
            {/* Site == name */}
            <FormField
              label="Name: "
            >
              <Input
                type="text"
                name="site"
                onChange={(e) => {
                  setIsDirty(true);
                  setSite(e.detail.value)
                }}
                value={site}
              />
            </FormField>
            <div className="entity-info">
              <FormField label="Entity Type:">
                <Select
                  selectedOption={selectedTypeOption}
                  placeholder="Select type"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedTypeOption(detail.selectedOption);
                  }}
                  options={typeOptions}
                />
              </FormField>
            </div>

            <div className="entity-info">
              <FormField label="Protective Group:">
                <Select
                  selectedOption={selectedProtectiveGroupOption}
                  placeholder="Select Protective group"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedProtectiveGroupOption(detail.selectedOption);
                  }}
                  options={protectiveGroupOptions}
                />
              </FormField>
            </div>

            <div>
              Confidential or restricted view:
              <fieldset>
                <RadioGroup
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setIsConfidentialOrRestricted(detail.value === "1" ? 1 : 0)
                  }}
                  value={"" + isConfidentialOrRestricted}
                  items={[
                    { value: "1", label: "Yes" },
                    { value: "0", label: "No" },
                  ]}
                />
              </fieldset>
            </div>

            <div>
              Carver Assessment:
              <fieldset>
                <RadioGroup
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setCarverAssessmentNeeded(detail.value === "1" ? 1 : 0)
                  }}
                  value={"" + carverAssessmentNeeded}
                  items={[
                    { value: "1", label: "Yes" },
                    { value: "0", label: "No" },
                  ]}
                />
              </fieldset>
            </div>

            {carverAssessmentNeeded === 1 && <ColumnLayout columns={2}

            >
              <Box>
                <FormField label="Criticality:">
                  <Select0to5
                    value={selectedCarverCriticality}
                    onChange={(newVal) => {
                      setSelectedCarverCriticality(parseInt(newVal));
                    }}
                  />

                </FormField>
                <FormField label="Accessibility:">
                  <Select0to5
                    value={selectedCarverAccessibility}
                    onChange={(newVal) => {
                      setSelectedCarverAccessibility(parseInt(newVal));
                    }}
                  />
                </FormField>
                <FormField label="Recoverability:">
                  <Select0to5
                    value={selectedCarverRecoverability}
                    onChange={(newVal) => {
                      setSelectedCarverRecoverability(parseInt(newVal));
                    }}
                  />
                </FormField>
                <FormField label="Vulnerability:">
                  <Select0to5
                    value={selectedCarverVulnerability}
                    onChange={(newVal) => {
                      setSelectedCarverVulnerability(parseInt(newVal));
                    }}
                  />
                </FormField>
                <FormField label="Effect:">
                  <Select0to5
                    value={selectedCarverEffect}
                    onChange={(newVal) => {
                      setSelectedCarverEffect(parseInt(newVal));
                    }}
                  />
                </FormField>

                <FormField label="Recognizability:">
                  <Select0to5
                    value={selectedCarverRecognizability}
                    onChange={(newVal) => {
                      setSelectedCarverRecognizability(parseInt(newVal));
                    }}
                  />
                </FormField>
              </Box>
              <Box><h2>Carver Score: {totalCarverScore} </h2></Box>
            </ColumnLayout>



            }

            <div className="entity-additional-info">
              {/* Location Entity Additional Fields form, should connect to the Entity input item to display, when selected above should set "active" class */}
              <div className="additional-fields active" id="location-entity">
                <div id="location-info">
                  <h3 className="title">Location</h3>

                  <span className="title">Region</span>
                  <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                      selectedOption={selectedRegionOption}
                      placeholder="Select region"
                      onChange={({ detail }) => {
                        setIsDirty(true)
                        setRegionOption(detail.selectedOption)
                        setSelectedRegion(detail.selectedOption.value!)

                      }}
                      options={RegionOptions}
                    />

                  </div>

                  <span className="title">AOR</span>
                  <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                      selectedOption={selectedAorOption}
                      placeholder="Select AOR"
                      onChange={({ detail }) => {
                        setIsDirty(true)
                        setSelectedAorOption(detail.selectedOption);
                        setSelectedAOR(detail.selectedOption.value!)
                      }}
                      options={AOROptions}
                    />

                  </div>

                  <span className="title">Country</span>
                  <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                      selectedOption={selectedCountryOption}
                      placeholder="Select Country"
                      onChange={({ detail }) => {
                        setIsDirty(true);
                        setSelectedCountryOption(detail.selectedOption);
                      }

                      }
                      options={countryOptions}
                    />
                  </div>
                </div>
              </div>

              <div>
                <FormField
                  label="State or Province: "
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      setIsDirty(true);
                      setState_province(e.detail.value);
                    }}
                    value={state_province}
                  />
                </FormField>
              </div>

              <div>
                <FormField
                  label="City:"
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      setIsDirty(true);
                      setCity(e.detail.value);
                    }}
                    value={city}
                  />
                </FormField>
              </div>

              <div>
                <FormField
                  label="Address: "
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      setIsDirty(true);
                      setAddress(e.detail.value)
                    }}
                    value={address}
                  />
                </FormField>
              </div>

              <span className="title">RSM</span>

              <div className="filter-style" id="asset-filter-dropdown">
                <Select
                  selectedOption={selectedRSMOption}
                  placeholder="Select RSM"
                  onChange={({ detail }) => {
                    setIsDirty(true)
                    setSelectedRSMOption(detail.selectedOption);
                  }}
                  options={RSMOptions}
                />
              </div>

              <span className="title">ASM</span>
              <div className="filter-style" id="asset-filter-dropdown">
                <Select
                  selectedOption={selectedASMOption}
                  placeholder="Select ASM"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedASMOption(detail.selectedOption)
                  }

                  }
                  options={ASMOptions}
                />
              </div>

              <FormField
                label="PSEC"
              >
                <Input
                  type="text"
                  name="PSEC"
                  value={PSEC}
                  onChange={(e) => {
                    setIsDirty(true);
                    setPSEC(e.detail.value);
                  }}
                />
              </FormField>
            </div>

            <FormField
              label="Consequence set: ">
              <Input
                type="text"
                onChange={(e) => {
                  setIsDirty(true);
                  setConsequenceSet(e.detail.value)
                }}
                value={consequenceSet}
              />
            </FormField>
          </div>
          <div>
            <div className="entity-info">
              <FormField label="Location Type:">
                <Select
                  selectedOption={selectedLocationTypeOption}
                  placeholder="Select location type"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedLocationTypeOption(detail.selectedOption);
                  }}
                  options={locationTypeOptions}
                />
              </FormField>
            </div>

            <FormField
              label="Context Statement: "
            >
              <Textarea
                onChange={(e) => {
                  setIsDirty(true);
                  setContextStatement(e.detail.value)
                }}
                value={contextStatement}
              />
            </FormField>


            <FormField
              label="Protective requirements: "
            >
              <Textarea
                onChange={(e) => {
                  setIsDirty(true);
                  setProtectiveRequirements(e.detail.value)
                }}
                value={protectiveRequirements}
              />
            </FormField>

          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default CS_EditEntityModal;
