import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import Tabs from "@amzn/awsui-components-react/polaris/tabs";

import AggregatedIncidentsAccordion from "./AggregatedIncidentsAccordion";
import TriagedIncidentsTable from "./TriagedIncidentsTable";
import RawIncidents from "./RawIncidents";
import NAandDupeIncidentsTable from "./NAandDupeIncidentsTable";
import { RoleContext } from "../RoleProvider";
import EmptyState from "../EmptyState";

const IncidentsTabsForWorkbench = () => {
  const { roleFetchComplete, userIsRiskAdmin } = useContext(RoleContext);
  if(!userIsRiskAdmin && roleFetchComplete){
    return <Navigate to="/" />
  }
  if(!roleFetchComplete) {
    return <EmptyState title="Loading incidents..." subtitle="" action="" />
  }
  
  return (
    <Tabs
      tabs={[
        {
          label: "Snapshot",
          id: "snapshot",
          content: <AggregatedIncidentsAccordion />
        },
        {
          label: "Raw (Pending Triage)",
          id: "raw",
          content: <RawIncidents />
        },
        {
          label: "Triaged",
          id: "triaged",
          content: <TriagedIncidentsTable showEditBtn={true} />,
        },
        {
          label: "N/A and Duplicates",
          id: "other",
          content: <NAandDupeIncidentsTable />,
        },
      ]}
    />
  );
}

export default IncidentsTabsForWorkbench;