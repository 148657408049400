import React from "react";
import {
  Select,
} from "@amzn/awsui-components-react";

export interface SelectLikelihoodProps { onChange: (e: any) => void, value: number, disabled?: boolean }
const SelectLikelihood = ({ disabled = false, onChange, value }: SelectLikelihoodProps) => {
  const theOptions = [
    { label: '1 - Rare', value: "1" },
    { label: '2 - Unlikely', value: "2" },
    { label: '3 - Possible', value: "3" },
    { label: '4 - Likely', value: "4" },
    { label: '5 - Almost Certain', value: "5" }
  ];

  return (
    <Select
      disabled={disabled}
      selectedOption={theOptions.filter((opt) => opt.value === value?.toString())[0] || theOptions[0]}
      onChange={({ detail }) => {
        onChange(detail.selectedOption.value);
      }}
      options={theOptions}
    />
  )
}

export default SelectLikelihood;