import React from "react";
import {
  Select,
} from "@amzn/awsui-components-react";

export interface Select0to5Props { onChange: (e: any) => void, value: number, showDescriptions?: boolean, disabled?: boolean }
const Select0to5 = ({ disabled = false, onChange, value, showDescriptions = true }: Select0to5Props) => {
  const theOptions = [
    { label: `0${showDescriptions ? ' - Not applicable' : ''}`, value: "0" },
    { label: `1${showDescriptions ? ' - Insignificant' : ''}`, value: "1" },
    { label: `2${showDescriptions ? ' - Minor' : ''}`, value: "2" },
    { label: `3${showDescriptions ? ' - Moderate' : ''}`, value: "3" },
    { label: `4${showDescriptions ? ' - Major' : ''}`, value: "4" },
    { label: `5${showDescriptions ? ' - Severe' : ''}`, value: "5" }
  ];

  return (
    <Select
      disabled={disabled}
      selectedOption={theOptions.filter((opt) => opt.value === value?.toString())[0] || theOptions[0]}
      onChange={({ detail }) => {
        onChange(detail.selectedOption.value);
      }}
      options={theOptions}
    />
  )
}

export default Select0to5;